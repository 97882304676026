<template>
	<div>
		<div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
			<span>当前位置:</span>
			企业形象<span>&gt;</span>
			公司概况
			<button class="veadmin-iframe-button" onclick="location.reload();">
				<i class="el-icon-refresh-right"></i>
			</button>
		</div>
		<div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
			<table style="width:400px;margin-bottom:10px;margin-top:20px;margin-left:20px;">
				<tr>
					<td style="width: 94px;margin-bottom: 15px;vertical-align: text-bottom;">
						<label class="el-form-item__label" style="width: 100%;">展示图片</label>
					</td>
					<td>
						<el-upload
							class="upload-demo"
							:action="this.apiServer + '/api/v1/admin/profile/add_or_update'"
							:auto-upload="false"
							:limit="1"
							:on-preview="handlePreview"
							:on-remove="handleRemove"
							:on-exceed="handleExceed"
							:on-success="onSuccess"
							:on-error="onError"
							:before-upload="beforeUpload"
							:file-list="fileList"
							:data="profile"
							:headers="header"
							name="image"
							ref="elUpload"
							list-type="picture"
							accept=".png,.jpg,.jpeg"
						>
							<el-button size="small" type="primary">选择本地图片</el-button>
							<div slot="tip" class="el-upload__tip">只能上传图片文件，且不超过1MB</div>
						</el-upload>
					</td>
				</tr>
			</table>

			<el-dialog :visible.sync="dialogVisible">
				<img width="100%" :src="dialogImageUrl" alt="" />
			</el-dialog>

			<el-form :model="profile" status-icon ref="elForm" label-width="100px" style="margin-left:20px;">
				<el-form-item label="公司概况" prop="text">
					<div id="wangEditor" style="margin-right:20px;"></div>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="saveProfile">保存</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
// 引入 wangEditor
import wangEditor from "wangeditor";
import { apiGetProfile } from "@/api/pub/profile.js";
import { apiUpdateProfile } from "@/api/admin/profile.js";
import base from "@/base.js";

export default {
	data() {
		return {
			apiServer: base.apiServer,
			submitElUpload: false,
			profile: {
				id: "",
				text: "",
				image_url: ""
			},
			header: { token: this.$store.state.token },
			fileList: [],
			dialogImageUrl: "",
			dialogVisible: false
		};
	},
	methods: {
		handleRemove(file, fileList) {
			this.submitElUpload = true;
			//console.log("handleRemove fileList>>", fileList);
			//console.log("handleRemove this.fileList>>", this.fileList);
		},
		handlePreview(file) {
			//console.log("handlePreview>>", file.url);
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		handleExceed() {
			this.$message.error("只能上传一张照片，请先删除之前选择的照片");
		},
		beforeUpload(file) {
			if (file.size > 1 * 1024 * 1024) {
				this.$message.error("图片大小不能超过1MB!");
				return false;
			} else {
				return true;
			}
		},
		// 图片上传成功
		onSuccess: function(response) {
			//console.log(response);
			if (response.code === 200) {
				this.$message({
					message: "保存成功",
					type: "success"
				});
				//执行成功1秒后刷新页面
				setTimeout(function() {
					location.reload();
				}, 1000);
			} else if (response.code == -100) {
				this.$confirm(response.data.message + "。将跳转至登录页面, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "error"
				})
					.then(() => {
						//跳转到登录页面（路由跳转则滑动验证会失效）
						//this.$router.push({ path: "/login?target=" + window.location.href });
						window.location.href = "/login?target=" + window.location.href;
					})
					.catch(() => {
						this.$message({
							type: "warning",
							message: "已取消"
						});
					});
			} else {
				console.log("response>>", response);
				this.$message.error(response.message);
			}
		},
		onError: function(response) {
			console.log("response>>", response);
			this.$message.error(response.message);
		},
		saveProfile() {
			//console.log(this.profile);
			//console.log(this.submitElUpload);
			if (this.submitElUpload) {
				//需要上传图片文件
				this.$refs.elUpload.submit();
			} else {
				//不需要上传图片文件
				let formData = new FormData();
				formData.append("id", this.profile.id);
				formData.append("text", this.profile.text);
				formData.append("image_url", this.profile.image_url);
				/* for (let [a, b] of formData.entries()) {
					console.log(a + ":", b || '""');
				} */
				apiUpdateProfile(this.$store.state.token, formData).then((response) => {
					if (response.data.code == 200) {
						this.$message({ message: "保存成功", type: "success" });
						//执行成功1秒后刷新页面
						setTimeout(function() {
							location.reload();
						}, 1000);
					} else if (response.data.code == -100) {
						this.$confirm(response.data.message + "。将跳转至登录页面, 是否继续?", "提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "error"
						})
							.then(() => {
								//跳转到登录页面（路由跳转则滑动验证会失效）
								//this.$router.push({ path: "/login?target=" + window.location.href });
								window.location.href = "/login?target=" + window.location.href;
							})
							.catch(() => {
								this.$message({
									type: "warning",
									message: "已取消"
								});
							});
					} else {
						console.log("response>>", response);
						this.$message.error(response.data.message);
					}
				});
			}
		},
		async getProfile() {
			try {
				const response = await apiGetProfile();
				//console.log(response);
				if (response.data.code == 200) {
					let profile = response.data.data;
					if (profile == null) {
						this.submitElUpload = true;
					} else {
						this.submitElUpload = false;
						this.profile = profile;
						this.editor.txt.html(this.profile.text);
						this.fileList = [{ name: "", url: this.apiServer + profile.image_url }];
					}
				} else {
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		}
	},
	mounted() {
		//生成编辑器
		const editor = new wangEditor("#wangEditor");
		//设置z-index
		editor.config.zIndex = 500;
		//默认编辑器占高300px,设置为320
		editor.config.height = 320;
		// 配置 onchange 回调函数，将数据同步到 vue 中
		editor.config.onchange = (newHtml) => {
			//console.log(newHtml);
			this.profile.text = newHtml;
		};
		// 创建编辑器
		editor.create();
		this.editor = editor;

		this.getProfile();
	}
};
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.upload-demo {
	width: 297px;
}
</style>
